angular.module('enervexSalesappApp').factory('DrawingUtil', function DrawingUtil(_, Application) {
	return {
		setApplicationTypePalette: function(application) {
			return {
				lineColor: application.lineColor,
				lineTriangleColor: application.lineTriangleColor,
				warningColor: application.warningColor,
				backgroundColor: application.backgroundColor,
				gridDotColor: application.gridDotColor,
				selectionColor: application.selectionColor,
				triangleLength: application.triangleLength,
				detailLineOffset: application.detailLineOffset,
				detailLineColor: application.detailLineColor,
				detailLineFontColor: application.detailLineFontColor,
				detailLineFont: application.detailLineFont,
				triangleFont: application.triangleFont,
				triangleFontColor: application.triangleFontColor,
				triangleBorderColor: application.triangleBorderColor
			}
		},
		setPalette: function(applications) {
			var self = this
			var defaults = {}
			_.each(applications, function(applicationType) {
				if (applicationType.code == "BWH") {
					defaults.boilerPalette = self.setApplicationTypePalette(applicationType)
				}
				if (applicationType.code == "COM") {
					defaults.combustionPalette  = self.setApplicationTypePalette(applicationType)
				}					
				if (applicationType.code == "DRY") {
					defaults.dryerPalette  = self.setApplicationTypePalette(applicationType)
				}					
				if (applicationType.code == "BOM") {
					defaults.bomPalette  = self.setApplicationTypePalette(applicationType)
				}					
				if (applicationType.code == "ENG") {
					defaults.generatorPalette  = self.setApplicationTypePalette(applicationType)
				}					
			})
			return defaults;
		},
		getDefaultPalette: function() {
			var self = this;
		    return Application.query().$promise.then(function(applications) {
		        return self.setPalette(applications);
		    });
		},
		getTriangleLengths: function() {
			return [ 24, 36, 48 ]
		},
		getTriangleFonts: function() {
			return [
				"7px HelveticaNeue",
				"8px HelveticaNeue",
				"9px HelveticaNeue",
				"10px HelveticaNeue",
				"12px HelveticaNeue",
				"14px HelveticaNeue",
				"16px HelveticaNeue",
				"18px HelveticaNeue",
				"20px HelveticaNeue",
				"22px HelveticaNeue",
				"24px HelveticaNeue",
			]
		},
		getLineOffsets: function() {
			return [ 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60 ]
		},
		getApplicationTypes: function() {
			return [{
				name: 'Boiler',
				value: 'boilerPalette'
			}, {
				name: 'Combustion Air Supply',
				value: 'combustionPalette'
			}, {
				name: 'Dryer',
				value: 'dryerPalette'
			}, {
				name: 'Bill of Materials',
				value: 'bomPalette'
			}, {
				name: 'Generator',
				value: 'generatorPalette'
			}]
		},
		setApplicationType: function(computeAs) {
			var applicationTypes = {
				'COM': 'combustionPalette',
				'BOM': 'bomPalette',
				'DRY': 'dryerPalette',
				'BWH': 'boilerPalette',
				'GEN': 'generatorPalette',
			};
			return applicationTypes[computeAs] || null; // Return null or handle the default case if needed
		},
		getCommonColorPreferences: function() {
			return [{
				name: "Background Color",
				value: "backgroundColor",
			}, {
				name: "Grid Dot Color",
				value: "gridDotColor",
			}, {
				name: "Warning Color",
				value: "warningColor",
			}, {
				name: "Line Color",
				value: "lineColor",
			}, {
				name: "Selection Color",
				value: "selectionColor",
			}]
		},
		getDetailLineColorOptions: function() {
			return [{
				name: "Detail Line Color",
				value: "detailLineColor",
			}, {
				name: "Detail Line Font Color",
				value: "detailLineFontColor",
			}]	
		},
		getTriangleColorOptions: function() {
			return [{
				name: "Triangle Color",
				value: "lineTriangleColor",
			}, {
				name: "Triangle Font Color",
				value: "triangleFontColor",
			}, {
				name: "Triangle Border Color",
				value: "triangleBorderColor"
			}]
		}, 
		
	}
})